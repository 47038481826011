import React from "react";
import "./App.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "./components/VerticalTimeline";

import ContractIcon from "./hand-shake-svg";
import EmploymentIcon from "./portfolio-svg";
import FreelanceIcon from "./idea-svg";
import GraduatedIcon from "./graduated-svg";

function App() {
  return (
    <div style={{ backgroundColor: "#cccccc" }}>
      <div className="Heading">
        <h1>Brian Herbert</h1>
        <h4>Experience Timeline</h4>
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="June 2021 - May 2024"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Army HQ</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Training Syllabus System & MyTraining
          </h4>
          <p>
                Brian helped to build systems with React, docker, .NET 8 and SQL Server, 
                hosted on secure internal Army Azure DevOps.
            <ul>
              <li>
                MyTraining allowed trainees and instructors to view courses and
                provide feedback.
              </li>
              <li>
                MODUS was used for managing Army training syllabi.
              </li>
            </ul>
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="March 2021 - June 2021"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Etch Group</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Big Bang Competition web app for Engineering UK
          </h4>
          <p>
            Brian built a web application from the ground up for the Big Bang
            Competition. This allowed competition entrants to enter the details
            for their projects so that they could be judged. The website
            authorised various user profiles with different activities such as
            judging, moderation, administration etc. There were also different
            competitions and prizes.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="November 2020 - February 2021"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Metrobank</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Contract .NET Developer
          </h4>
          <p>
            Enhancing an Online Account Opening form built with BackboneJS. This
            connected to the Dynamics 365 CRM backend system.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="October 2020"
          contentStyle={{ background: "aquamarine", color: "#555" }}
          contentArrowStyle={{ borderRight: "7px solid  aquamarine" }}
          iconStyle={{ background: "aquamarine", color: "#555" }}
          icon={<FreelanceIcon />}
        >
          <h3 className="vertical-timeline-element-title">Salutis</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Freelance MVC Development
          </h4>
          <p>
            Brian helped to upgrade an existing permit-to-work system from a
            Windows desktop application (WinForms) to a web application.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="March 2020 - August 2020"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Kalibrate Technologies
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Senior Software Developer
          </h4>
          <p>
            Brian worked on the Continuous Integration project, migrating the
            build pipelines from Jenkins into Azure DevOps and supported various
            products. The support work included a Cordova mobile application and
            the flagship AngularJS product.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Januar 2019 - January 2020"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Open Money</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Angular, .NET & Azure Contract Developer
          </h4>
          <p>
            Full stack web development with Angular, Service Fabric and C# .NET.
            Brian worked to deliver the quick check customer portal and
            developed various areas of the website. He also worked extensively
            to deliver cloud services which were implemented with .NET core
            Function Apps and Service Fabric's actor pattern. These services
            were deployed through Microsoft DevOps, using continuous integration
            builds, and Octopus deploy was used to manage environments.
            Terraform was used by the platform engineers to create the
            environments.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          date="Apr 2018 - Nov 2018"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            SABS (Corporate Travel Management)
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Technical Lead</h4>
          <p>
            Full stack web development working with Azure and .NET. This
            involved using Azure Service Fabric, Azure SQL, App Services &
            various other Azure products. A website was developed in React.
            Brian worked as a member for a globally distributed team, working on
            the European features of a global system including managing
            stakeholders. Brian was also responsible for recruiting a contractor
            for a short-fixed price project.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          date="Sep 2017 - Mar 2018"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">Auden</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Senior Software Developer
          </h4>
          <p>
            Backend developer using C#, ASP .NET & Azure. Brian worked on a
            backend financial system for funding loans. He also worked on a
            reporting system for loan repayment schedules. This involved
            learning about new Azure products such as API Gateways and Function
            Apps. Programming was done with C# .NET core 2.0 and .NET Framework.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          date="Oct 2015 - Sep 2017"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">webuyanycar.com</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Senior Software Developer
          </h4>
          <p>
            Worked on a dedicated appointment scheduling system for buying cars
            and allocating vehicle purchasers to various sites nationwide. Brian
            also worked on various systems using Angular, SQL Server and REST
            micro- services with .NET Web API. Brian automated deployments with
            Octopus Deploy, git and TFS Build. His last project was to create
            the webuyanycar mobile app using Xamarin with extensive unit testing
            in the back-end.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="May 2015 - Aug 2015"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            MD Insurance Services
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET MVC Contract Developer
          </h4>
          <p>
            MDIS provides insurance services for the building industry. Brian
            was involved with completing an extranet website which linked to a
            CRM (Sapiens). This was done with ASP .NET MVC, the identity
            framework membership provider for authentication, EF6 and JavaScript
            libraries including jQuery.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Oct 2014 - Mar 2015"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">NICE</h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET MVC (Orchard CMS) Contract Developer
          </h4>
          <p>
            The National Institute for Health and Care Excellence (NICE)
            provides guidance for the NHS. Brian's role was to improve the
            nice.org.uk website, an MVC based Content Management System (Orchard
            CMS). He customised a sitemap module, created a module to migrate
            data from an existing system into Orchard CMS and fixed a number of
            bugs in the code.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Aug 2014 - Oct 2014"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Nitecrest</h3>
          <h4 className="vertical-timeline-element-subtitle">
            .NET Contract Developer
          </h4>
          <p>
            Nitecrest is a multi-national company which is the largest
            manufacturer of plastic cards and similar products. Brian’s role was
            to trouble-shoot various back-end systems for handling input files
            and processing them into data files for the manufacturing process.
            This involved working a number of technologies which included ASP
            .NET MVC, C#, MSSQL and Entity Framework 6.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Jun 2014 - Aug 2014"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Unicom</h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET Webforms SQL Server Contract Developer
          </h4>
          <p>
            Brian added various new pages to Unicom’s ASP .NET webforms CRM
            system and altered a number of customer-facing MVC pages. This
            system monitored and reported on staff absence, customer data and
            provided a public facing site.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "aquamarine", color: "#555" }}
          contentArrowStyle={{ borderRight: "7px solid  aquamarine" }}
          date="Feb 2014 - May 2014"
          iconStyle={{ background: "aquamarine", color: "#555" }}
          icon={<FreelanceIcon />}
        >
          <h3 className="vertical-timeline-element-title">Promtek</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Freelance Android Developer
          </h4>
          <p>
            Promtek provides process control systems and weigher calibration
            systems for the animal feed market and food manufacturing processes.
            Brian created a system for managing Service Engineer workloads and
            calibration certificates; an Android application and a back-office
            admin portal were connected with Google Cloud Messaging. The admin
            portal was able to queue jobs for the Android application and the
            job outcomes would be uploaded and displayed in admin portal. The
            backend system was an ASP .NET Web API with a SQL Azure Database,
            hosted in Azure.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "aquamarine", color: "#555" }}
          contentArrowStyle={{ borderRight: "7px solid  aquamarine" }}
          date="Jun 2013 - Oct 2013"
          iconStyle={{ background: "aquamarine", color: "#555" }}
          icon={<FreelanceIcon />}
        >
          <h3 className="vertical-timeline-element-title">Jaguar Land Rover</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Freelance ASP .NET Developer
          </h4>
          <p>
            An interactive forms website was produced for consumption by the
            network of Jaguar and Land Rover dealerships around the world. It
            supported 13 languages including non-Latin texts and produced a
            downloadable PDF document. This website was managed by Brian until
            JLR moved to an in-house equivalent.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Aug 2013 - Sep 2013"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">WebNET</h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET Webforms Contract Developer
          </h4>
          <p>
            WebNET provide document management solutions for a number of
            electricity providers. Brian was brought in to fix a number of bugs
            for a particular client so that the system could be delivered to a
            tight schedule.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Feb 2013 - Mar 2013"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Matrix Telematics</h3>
          <h4 className="vertical-timeline-element-subtitle">
            C# .NET Contract Developer
          </h4>
          <p>
            Backend Windows Services with C# .NET 4 and MySQL databases. Brian
            was involved with optimising a real- time alerts system for sending
            out notifications when a vehicle moves into a specific area on the
            map.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Aug 2012 - Feb 2013"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Manheim</h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET Webforms Contract Developer
          </h4>
          <p>
            This project was based around a Service Oriented Architecture and a
            number of design patterns, involving ASP .NET, MVC and multi-tier
            architecture. Brian used a CQRS design pattern to implement a
            message queue for sending SMS message via third party providers. He
            also debugged various web pages and added user controls.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Jan 2012 - Jul 2012"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Daniel Contractors
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET MVC 3 Contract Developer
          </h4>
          <p>
            This contract involved programming with ASP .NET 3.5 and Entity
            Framework 3. The main responsibility was to create a website using
            MVC that would provide the User Interface for an existing customer
            database. Integration testing was implemented using Selenium. Other
            responsibilities included Winforms development work with Unity for
            IoC, xUnity for TDD and agile methodologies.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Nov 2011 - Dec 2011"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">AI Claims</h3>
          <h4 className="vertical-timeline-element-subtitle">
            ASP .NET MVC 3 Contract Developer
          </h4>
          <p>
            This contract involved programming with ASP .NET 4.0. Initially
            customising an existing MVC 2 website and then moved onto separate
            MVC 3 websites, this contract also involved experience with the
            Entity Framework and NUnit.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Feb 2011 - Aug 2011"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">HSS</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Programmer Analyst
          </h4>
          <p>
            As a Senior Developer at HSS Tool Hire Brian's duties included
            business analysis as well as programming. Test Driven Development
            was used for creating Windows Services and various intranet sites
            were implemented using C# ASP .NET programming with SQL Server and
            Oracle back-ends. This involved extensive database work with Castle
            Active Record (NHibernate).
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Dec 2010 - Jan 2011"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">Celestrica</h3>
          <h4 className="vertical-timeline-element-subtitle">
            C# ASP .NET Contract Developer
          </h4>
          <p>
            7 weeks C# ASP .NET programming with SQL Server 2003, TFS and Visual
            Studio 2008. Essentially this project was involved with updating a
            classic ASP website to work with Webforms for the multi-national
            reverse logistics company. The project followed Scrum practices,
            with a proper scrum master.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
          date="Jan 2010 - Jun 2010"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<ContractIcon />}
        >
          <h3 className="vertical-timeline-element-title">BAE System</h3>
          <h4 className="vertical-timeline-element-subtitle">
            .NET Contract Software Engineer
          </h4>
          <p>
            Initially a 3 month contract at Warton, Lancashire it was extended
            to 5 months with overtime: Programming for the Aerospace industry in
            C# 3.5 using SQL, WCF and XML serialization. Brian worked on a
            modular application for managing file transfers across secure
            networks which allowed the Design Engineers to deploy files for
            testing and manufacturing of aircraft parts.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Oct 2009 - Nov 2009"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">Andrews Online</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Programmer Analyst
          </h4>
          <p>
            Andrews are a Real Estate and Property Management company with
            headquarters in Keynsham. Brian worked on backend systems for
            connecting to Dynamics CRM & sending email newsletters.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="May 2006 - Jul 2009"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3
            className="vertical-timeline-element-title"
            title="Civica Digital"
          >
            IPL
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Programmer</h4>
          <p>
            Brian was involved with all aspects of developing and maintaining an
            internal staff database and accounting application for this software
            house. This was done in C# 2.0, MS SQL, winforms and ASP .NET
            webforms. Brian also worked as part of a team of developers on
            various projects for Nationwide Building Society.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="May 2004 - May 2006"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<EmploymentIcon />}
        >
          <h3 className="vertical-timeline-element-title">Renishaw</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Engineering Graduate
          </h4>
          <p>
            Working in a culture of innovation Renishaw provided solid
            engineering experience. Brian worked in the Electronic Production
            Engineering where he programmed an optical inspection machine using
            a drag & drop interface (Agilent SJ50). Later, in the Software
            Engineering Department Brian developed software with C# WinForms,
            SQL Server and Object Oriented methodologies Engineering.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          date="2004"
          iconStyle={{
            background: "rgb(16, 204, 82)",
            color: "#fff",
            title: "start",
          }}
          icon={<GraduatedIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Loughborough University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Master of Engineering (MEng)
          </h4>
          <p>Electronic & Computer Systems Engineering</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default App;
